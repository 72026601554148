<template>
	<div class="room-add-player">
		<div class="bg" v-hammer:tap="() => close()"></div>
		<div class="room-add-player-content">
			<div class="column form-add-players">
				<div class="text">Enter the student's name</div>
				<div class="counter">
					<span class="arrow left" v-hammer:tap="() => decrement()"></span>
					<span class="no">{{ students.length }}</span>
					<span class="arrow right" v-hammer:tap="() => increment()"></span>
				</div>
				<form class="form" @submit.prevent="submit()">
					<div class="form-content scroll" v-scrollbar>
						<div>
							<student v-for="student in students" :student="student" :removable="students.length > 1" :key="student.id" @remove="remove_student" />
						</div>
					</div>
					<div class="buttons">
						<ui-button :loading="loading" :inactive="valid_students.length == 0" v-hammer:tap="() => submit()"><span>Add {{ valid_students.length }} player<span v-show="valid_students.length > 1">s</span></span></ui-button>
					</div>
				</form>
				<div class="close" v-hammer:tap="() => close()"></div>
			</div>
			<div class="column list-players">
				<div class="title">Name's already in game</div>
				<div class="list-player-content" v-scrollbar>
					<div>
						<div v-for="(player, index) in players" :key="index" class="player-item">{{ player.student_number }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Student from '@/components/master/room/add-player/student'
import UiButton from '@/components/ui/ui-button'
import { slugify } from '@/js/utils/utils'

let id = 1
export default {
	name: 'room-add-player',
	data() {
		return {
			loading: false,
			students: [ { id, student_number: "" } ]
		}
	},
	computed: {
		...mapGetters([ 'game' ]),
		players() {
			return this.game.players.filter((p) => { return !p.role || (p.role && !p.role.npc) })
		},
		valid_students() {
			return this.students.filter((student) => {
				return student.student_number && this.game.players.filter((p) => { return slugify(p.student_number) == slugify(student.student_number) }).length == 0
			})
		}
	},
	methods: {
		decrement() {
			if (this.students.length > 1) this.students.pop()
		},
		increment() {
			this.students.push({ id: ++id, student_number: "" })
		},
		remove_student(data) {
			if (data.student && this.students.length > 1) {
				let index = this.students.indexOf(data.student)
				if (index > -1) this.students.splice(index, 1)
			}
		},
		async submit() {
			if (this.valid_students.length > 0) {
				this.loading = true
				await this.$store.dispatch('add_players', { players: this.valid_students })
				this.close()
			}
		},
		close() {
			this.$router.go(-1)
		}
	},
	components: { UiButton, Student }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.room-add-player
	fixed 0
	top 80px
	bottom 80px
	flex flex-start center
	// height 100%
	.bg
		absolute 0
		background-color alpha(blue_dark, 85%)
	.room-add-player-content
		margin-left 400px
		flex center center
		height 100%
		.column
			height calc(100% - 64px)
			padding 40px
			border-radius 8px
			box-shadow 0 0 0 2px blue_lighter
			background-color blue_dark
			&.form-add-players
				width 600px
				margin 0 8px 0 0
				padding-top 64px
				flex flex-start center
				flex-direction column
			&.list-players
				padding 40px 0
				margin 0 0 0 8px
				width 300px
			.title
				flex center center
				padding 0 32px
				margin 0 0 40px 0
				color blue_lighter
				text-align center
				text-transform uppercase
				font-size 2.4rem
				font-family 'Josefin Sans'
			.text
				color blue_lighter
				font-size 1.6rem
				text-align center
				text-transform uppercase
				line-height 20px
			.counter
				flex center center
				margin 24px 0
				height 64px
				text-align center
				color blue_lighter
				.arrow, .no
					display inline-block
					height 64px
					width 64px
					margin 0 8px
				.no
					font-size 4rem
					font-family 'Josefin Sans'
					line-height 68px
				.arrow
					cursor pointer
					&:before
						content ''
						position absolute
						left (32px - 12px)
						top (32px - 16px)
						width 0
						height 0
						border-style solid
					&.left:before
						border-width 16px 24px 16px 0
						border-color transparent blue_lighter transparent transparent
					&.right:before
						border-width 16px 0 16px 24px
						border-color transparent transparent transparent blue_lighter
			.form
				width 100%
				height calc(100% - 196px)
				.buttons
					margin 24px 0 0 0
					flex center center
					>>> .ui-button .button
						border-radius 8px
						background-color #3FB143
						text-transform uppercase
						font-size 1.8rem
						font-family 'Josefin Sans'
						.loader
							border-radius 8px
							&.loading
								background-color #3FB143
						> span
							transform translateY(2px)
			.list-player-content
				height calc(100% - 80px)
				.player-item
					color blue_lighter
					line-height 40px
					white-space nowrap
					text-transform uppercase
					font-size 1.8rem
					text-align center
					font-family 'Josefin Sans'
		
		.close
			position absolute
			right 24px
			top 24px
			width 40px
			height 40px	
			cursor pointer
			background url(../../../assets/img/close-round-white.svg) center center no-repeat
			background-size 100% auto
	
</style>
