<template>
	<div class="assignement" :class="{ dropping: !player.role_locked && master.dropping_player.id == player.id, droppable: !player.role_locked && master.dragging_role, locked: player.role_locked }" @mouseenter="enter($event)" @mouseleave="leave($event)">
		<div class="remove" v-hammer:tap="() => remove_player()">
			<ui-icon name="close-blue-lighter" :size="16" />
			<ui-icon v-if="removing" class="remove-loader" name="loader" :size="40" />
		</div>
		<div class="name">
			<span v-if="player.student_name">{{ player.student_name }}</span>
			<span v-else>{{ player.student_number }}</span>

			<ui-link :to="'/master/rooms/' + game.room.id + '/roles/edit-player/' + player.id" class="edit"><ui-icon name="edit" :size="16" /></ui-link>
		</div>
		<div class="role">
			<div v-if="player.role && player.role.name != 'Default'" class="assignement-role">
				<div class="name">{{ player.role.name }}</div>
				<div class="actions">
					<div class="action lock" v-hammer:tap="() => lock_role()">
						<ui-icon v-if="player.role_locked" name="master/lock" :size="16" />
						<ui-icon v-else name="master/unlock" :size="16" />
					</div>
					<div class="action remove" v-hammer:tap="() => remove_player_role()"><ui-icon name="close" :size="16" /></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'assignement',
	data() {
		return {
			removing: false
		}
	},
	props: {
		player: { default: null }
	},
	computed: {
		...mapGetters([ 'master', 'game' ])
	},
	methods: {
		enter(e) {
			if (this.master.dragging_role && !this.player.role_locked) {
				this.$store.commit('EDIT_MASTER_STATE', { dropping_player: this.player })
				this.$el.classList.add('dropping')
			}
		},
		leave(e) {
			this.$store.commit('EDIT_MASTER_STATE', { dropping_player: false })
		},
		lock_role() {
			if (this.player.role) {
				let role_locked = true
				if (this.player.role_locked) role_locked = false
				this.$store.commit('EDIT_PLAYER', { player: this.player, role_locked })
			}
		},
		remove_player_role() {
			if (this.player.role && !this.player.role.locked) {
				this.$store.commit('EDIT_PLAYER', { player: this.player, role: false, role_locked: false })
			}
		},
		async remove_player() {
			if (!this.removing) {
				this.removing = true
				await this.$store.dispatch('remove_player', { player: this.player })
				this.removing = false
			}
		},
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.assignement
	flex flex-start center
	height 40px
	line-height 40px
	margin 0 0 8px 0
	&.droppable
		&.dropping
			.role:after
				background-color alpha(blue_light, 50%)
				border 2px dashed blue_lighter
		.role:after
			display block
			background-color alpha(blue, 50%)
			border-radius 4px
			box-shadow none
			border 2px dashed blue_light
	&.locked
		.role
			.assignement-role
				color alpha(#fff, 40%)
				background-color transparent
				.actions
					background-color transparent
	&:hover
		> .name
			.edit
				opacity 0.5
				&:hover
					opacity 1
	> .remove
		width 40px
		height 40px
		flex center center
		cursor pointer
		opacity 0.5
		&:hover
			opacity 1
		.remove-loader
			absolute 0
			background-color blue_dark
	> .name
		height 40px
		width 250px
		padding 0 16px
		border-radius 4px
		background-color blue
		text-transform uppercase
		color blue_lighter
		.edit
			position absolute
			right 0
			top 0
			height 40px
			width 24px
			flex center center
			opacity 0
			cursor pointer
	.role
		height 40px
		margin-left 8px
		width calc(100% - 298px)
		box-shadow inset 0 -2px 0 0 blue
		
		&:after
			content ''
			display none
			absolute 0
		.assignement-role
			height 40px
			width 100%
			padding 0 16px
			border-radius 4px
			background-color blue
			text-transform uppercase
			color blue_lighter
			.name
				white-space nowrap
				overflow hidden
			.actions
				position absolute
				right 8px
				top 0
				flex center center
				background-color blue
				.action
					width 32px
					height 40px
					flex center center
					cursor pointer
					opacity 0.5
					&:hover
						opacity 1
	
</style>
