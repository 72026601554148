<template>
	<div class="room-stats">

		<div class="tabs">
			<div class="tab" :class="{ active: tab === 0 }" v-hammer:tap="() => tab = 0">Units</div>
			<div class="tab" :class="{ active: tab === 1 }" v-hammer:tap="() => tab = 1">Carbon reduction per country</div>
			<div class="tab" :class="{ active: tab === 2 }" v-hammer:tap="() => tab = 2">Global carbon reduction per year</div>
			<div class="tab" :class="{ active: tab === 3 }" v-hammer:tap="() => tab = 3">Average carbon reduction per country</div>
		</div>

		<div class="tab-content">
			<div class="scroll" v-scrollbar>
				<div>
					<stats-units v-if="tab === 0" />
					<carbon-reduction-per-country v-if="tab === 1" />
					<global-carbon-reduction-per-year v-if="tab === 2" />
					<average-carbon-reduction-per-country v-if="tab === 3" />
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatsUnits from '@/components/game/stats/stats-units'
import CarbonReductionPerCountry from '@/components/game/stats/carbon-reduction-per-country'
import GlobalCarbonReductionPerYear from '@/components/game/stats/global-carbon-reduction-per-year'
import AverageCarbonReductionPerCountry from '@/components/game/stats/average-carbon-reduction-per-country'

export default {
	name: 'room-stats',
	data() {
		return {
			tab: 0
		}
	},
	computed: {
		...mapGetters([ 'app', 'master' ]),
	},
	components: { StatsUnits, CarbonReductionPerCountry, GlobalCarbonReductionPerYear, AverageCarbonReductionPerCountry }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.room-stats
	height 100%
	padding 16px 0 0 0
	.tabs
		flex flex-start flex-end
		height 48px
		border-bottom 2px solid blue_lighter
		.tab
			display inline-block
			height 100%
			width 184px
			padding 0 8px
			margin 0 0 0 8px
			background-color blue_light
			color blue_dark
			flex center center
			flex-wrap wrap
			text-align center
			font-family 'Josefin Sans'
			font-weight 700
			text-transform uppercase
			cursor pointer
			&:hover
				background-color lighten(blue_light, 10%)
			&.active
				background-color blue_lighter
	
	.tab-content
		height calc(100% - 48px)

</style>
