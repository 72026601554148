<template>
	<div class="student">
		<div class="input" :class="{ error: already_in_game }">
			<ui-input type="text" v-model="student.student_number" placeholder="Student name" />
			<div class="error"><ui-icon name="alert-circle" :size="24" /><div class="message">Student already in game</div></div>
		</div>
		<!-- <span class="separator"> : </span> -->
		<!-- <div class="input">
			<ui-input type="text" v-model="student.student_name" placeholder="Name" />
		</div> -->
		<div v-if="removable" class="remove-button" title="Remove" v-hammer:tap="() => remove_student()"><ui-icon name="close-blue-lighter" :size="16" /></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiInput from '@/components/ui/ui-input'
import { slugify } from '@/js/utils/utils'

export default {
	name: 'student',
	data() {
		return {
			
		}
	},
	props: {
		student: { default: null },
		removable: { default: false }
	},
	computed: {
		...mapGetters([ 'game' ]),
		already_in_game() {
			return this.game.players.find((p) => { return slugify(p.student_number) == slugify(this.student.student_number) })
		}
	},
	methods: {
		remove_student() {
			if (this.removable) this.$emit('remove', { student: this.student })
		}
	},
	components: { UiInput }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.student
	margin 0 0 8px 0
	height 40px
	flex flex-start center
	.input
		// width 'calc(50% - %s)' % (20px + 12px)
		width 100%
		height 40px
		&.error
			.ui-input
				box-shadow inset 0 0 0 2px red
				background-color alpha(red, 20%)
				&:focus
					background-color alpha(red, 25%)
			> .error
				display flex
		.ui-input
			background-color alpha(#fff, 15%)
			height 40px
			padding 0 16px
			border-radius 4px
			color #fff
			&:focus
				background-color alpha(#fff, 25%)
			&::-webkit-input-placeholder
				color #fff
				opacity 0.5
			&::-moz-placeholder
				color #fff
				opacity 0.5
			&:-ms-input-placeholder
				color #fff
				opacity 0.5
			&:-moz-placeholder
				color #fff
				opacity 0.5
		> .error
			position absolute
			right 8px
			top 8px
			flex center center
			display none
			cursor help
			z-index 1
			&:hover
				.message
					display block
			.message
				display none
				position absolute
				left 50%
				top calc(100% + 8px)
				transform translateX(-50%)
				padding 4px 12px
				border-radius 4px
				background-color red
				font-size 1.2rem
				color #fff
				white-space nowrap
				&:before
					content ''
					position absolute
					top -6px
					left calc(50% - 4px)
					width 0
					height 0
					border-style solid
					border-width 0 4px 4px 4px
					border-color transparent transparent red transparent
				&:hover
					display none

	.separator
		height 40px
		width 24px
		flex center center
	.remove-button
		height 40px
		width 40px
		flex center center
		cursor pointer
		border-radius 50%
		opacity 0.5
		&:hover
			opacity 1
	
</style>
