<template>
	<div class="card" :class="{ draggable: draggable, dragging: master.dragging_role.id == role.id, affected: affected_player }" v-hammer:panstart="($event) => panstart($event)" v-hammer:pan="($event) => pan($event)" v-hammer:panend="() => panend()">
		<div class="background" :class="category_slug"></div>
		<div class="name">{{ role.name }}</div>
		<div class="difficulty">
			<div class="label">Role<br>difficulty</div>
			<div class="value">
				<span class="nb">{{ role.difficulty }}</span>
				<span class="stars">
					<ui-icon :name="'master/star-' + role.difficulty" :size="32" />
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { slugify } from '@/js/utils/utils'
export default {
	name: 'card',
	data() {
		return {
			drag_ready: true
		}
	},
	props: {
		role: { default: null },
		draggable: { default: false }
	},
	computed: {
		...mapGetters([ 'master', 'game' ]),
		affected_player() {
			return this.game.players.find((player) => { return player.role && player.role.id == this.role.id })
		},
		category_slug() {
			if (this.role && this.role.category) return slugify(this.role.category)
			return ''
		}
	},
	methods: {
		panstart(e) {
			if (this.drag_ready) {
				if (!this.master.dragging_role) this.$store.commit('EDIT_MASTER_STATE', { dragging_role: this.role })
				this.$nextTick(() => {
					let el = document.querySelector('#dragging-role')
					let card_rect = this.$el.getBoundingClientRect()
					el.style.left = card_rect.x + 'px'
					el.style.top = card_rect.y + 'px'
					el.style.width = card_rect.width + 'px'
					el.style.height = card_rect.height + 'px'
					this.drag_ready = false
				})
			}
		},
		pan(e) {
			if (!this.drag_ready) {
				let el = document.querySelector('#dragging-role')
				if (el) el.style.transform = 'translate(' + e.deltaX + 'px, ' + e.deltaY + 'px)'
			}
		},
		panend() {
			let el = document.querySelector('#dragging-role')
			if (el) {
				el.style.transform = ''
				el.classList.add('back')
			}

			if (this.master.dragging_role && this.master.dropping_player) {
				if (this.affected_player) this.$store.commit('EDIT_PLAYER', { player: this.affected_player, role: false, role_locked: false })
				this.$store.commit('EDIT_PLAYER', { player: this.master.dropping_player, role: this.master.dragging_role, role_locked: false })
				this.back_card(el)
			} else {
				setTimeout(() => {
					this.back_card(el)
				}, 250)
			}
		},
		back_card(el) {
			this.$store.commit('EDIT_MASTER_STATE', { dragging_role: false })
			this.drag_ready = true
			if (el) el.classList.remove('back')
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'

.card
	height 180px
	background-color blue
	border-radius 4px
	padding 16px
	user-select none
	&.draggable
		cursor move
	&.dragging, &.affected
		opacity 0.15
	&.dragging-role
		opacity 1
		box-shadow inset 1px 1px 0 0 lighten(blue, 10%), 4px 4px darken(blue_darker, 25%)
		&.back
			transition transform 0.25s easeOutQuart
			box-shadow none
	.background
		absolute 16px
		background center center no-repeat
		background-size contain
		&.government
			background-image url(../../../../assets/img/emblems/government.svg)
		&.lawenforcement
			background-image url(../../../../assets/img/emblems/law-enforcement.svg)
		&.citizens
			background-image url(../../../../assets/img/emblems/citizens.svg)
		&.media
			background-image url(../../../../assets/img/emblems/media.svg)
	.name
		text-align center
		text-transform uppercase
		color #fff
		font-family 'Josefin Sans'
		font-size 1.4rem
		line-height 20px
	.difficulty
		position absolute
		left 8px
		bottom 8px
		right 8px
		height 48px
		padding 8px
		background-color blue_dark
		border-bottom-left-radius 4px
		border-bottom-right-radius 4px
		flex center center
		color blue_lighter
		.label
			flex flex-end center
			width 50%
			height 100%
			padding-right 8px
			text-align right
			font-size 1.2rem
			line-height 16px
			border-right 1px solid blue_lighter
		.value
			flex flex-start center
			padding-left 8px
			width 50%
			height 100%
			border-left 1px solid blue_lighter
			.nb
				font-size 3.2rem
			.stars
				height 100%
				width 24px
				margin-left 4px
	
	
</style>
