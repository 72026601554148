<template>
	<div class="single-message" :class="type">
		<div class="head">
			<div class="sender">
				<div v-if="type === 'group'" class="avatar">
					<div class="img" v-bg="{ name: message.sender.avatar, type: 'avatar' }">
						<div v-if="message.sender.student_number !== 'N/A'" class="online"></div>
					</div>
				</div>
				<div class="player-name">
					<span v-if="message.sender_player">{{ message.sender_player.student_name }}</span>
					<span v-else>Unknown</span>
				</div>
				<div class="user-name">[{{ message.sender.name }}]</div>
			</div>
			<div class="send-to" v-if="type === 'private'">
				<div class="text">Send to</div>
				<ui-icon name="right-arrow-blue-light" :size="16" />
			</div>
			<div class="recipient" v-if="type === 'private'">
				<div class="player-name">
					<span v-if="message.recipient_player">{{ message.recipient_player.student_name }}</span>
					<span v-else>Unknown</span>
				</div>
				<div class="user-name">[{{ message.recipient.name }}]</div>
			</div>

			<div v-if="type === 'group'" class="channel">
				<span class="label">Channel:</span>{{ message.channel }}
			</div>
		</div>
		<div class="content">
			<div class="message">
				<ui-text :text="message.message" />
			</div>
		</div>
	</div>
</template>

<script>
import UiText from '@/components/ui/ui-text'

export default {
	name: 'single-message',
	props: {
		message: { default: null },
		type: { default: 'group' },
	},
	components: { UiText }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/variables'


.single-message
	display block
	margin 0 0 32px 0
	&.private
		border-left 2px solid blue_lighter
		padding-left 16px
	&.group
		.head
			margin 0 0 12px 0
			.sender
				width 100%
				.avatar
					position absolute
					left 0
					top 0
					width 40px
					height 40px
					border-radius 50%
					background-color green_light
					.img
						width 100%
						height 100%
						background center center no-repeat
						background-size cover
						border-radius 50%
						.online
							position absolute
							right 0
							top 0
							width 16px
							height 16px
							background-color #1BD6BD
							box-shadow 0 0 0 4px blue_dark
							border-radius 50%
				.player-name
					margin-left 52px
				.user-name
					margin-left 52px
			.channel
				position absolute
				right 0
				top 0
				white-space nowrap
				text-transform uppercase
				.label
					display inline-block
					margin 0 8px 0 0
					opacity 0.25
		.content
			margin 8px 0 0 0
			.message
				&:before
					display none
	.head
		min-height 40px
		line-height 18px
		// white-space nowrap
		flex flex-start flex-start
		.sender, .recipient
			height 100%
			width 40%
			.player-name
				display block
				margin 0 0 4px 0
			.user-name
				display block
				opacity 0.5
				
		.send-to
			width 14%
			margin 0 6% 0 0
			flex center flex-end
			flex-direction column
			text-align right
			color blue_lighter
			text-transform uppercase
			font-family 'Josefin Sans'
			.text
				transform translateY(4px)
				margin 0 0 4px 0
	.content
		margin 24px 0 0 0
		.message
			padding 16px
			border-radius 12px
			background-color #01405A
			&:before
				content ''
				position absolute
				left 24px
				top -16px
				width 0
				height 0
				border-style solid
				border-width 16px 0 0 16px
				border-color transparent transparent transparent @background-color
			>>> .ui-text
				p
					&:last-child
						margin-bottom 0

</style>
