<template>
	<div class="room-roles">
		
		<div class="room-roles-content">
			<div class="title">Role assignment</div>
			<div class="assignements" v-scrollbar>
				<div>
					<div v-if="players.length > 0" class="list-assignements">
						<assignement v-for="player in players" :player="player" :key="player.id" />
					</div>
					<div class="no-players" v-else>
						<p class="label">There are no players in the room.</p>
						<p><ui-link :to="'/master/rooms/' + game.room.id + '/roles/add-player'" class="button">Add players</ui-link></p>
					</div>
				</div>
			</div>
			<div class="roles">
				<div class="tabs">
					<div v-for="tab in tabs" class="tab" :class="{ active: active_tab == tab }" v-hammer:tap="() => goto_slide(tab)">{{ tab }}</div>
				</div>
				<hooper class="slider-roles" :settings="roles_slider_settings" ref="carousel" @slide="slide">
					<slide v-for="role in roles" :key="role.id" :data-category="role.category">
						<card :role="role" :draggable="true" />
					</slide>
				</hooper>
				<div class="slide-arrow left" v-hammer:tap="() => prev()"></div>
				<div class="slide-arrow right" v-hammer:tap="() => next()"></div>
			</div>
			<card v-if="master.dragging_role" :role="master.dragging_role" class="dragging-role" id="dragging-role" />
		</div>

		<div class="room-roles-side">
			<ui-link :to="'/master/rooms/' + game.room.id + '/roles/add-player'" class="action">
				<ui-icon name="master/new-game" :size="24" />
				<span>Add player</span>
			</ui-link>
			<br>
			<div class="action" v-hammer:tap="() => assign_roles()">
				<ui-icon name="master/roles-wiki" :size="32" />
				<span>Assign roles<br><span class="small">to players without role</span></span>
			</div>
			<div class="action" v-hammer:tap="() => shuffle_roles()">
				<ui-icon name="master/shuffle" :size="32" />
				<span>Shuffle roles<br><span class="small">(Affects unlocked roles)</span></span>
			</div>
			<ui-link :to="'/master/rooms/' + game.room.id + '/roles/validate'" class="action green">
				<ui-icon name="master/lock" :size="32" />
				<span>Confirm roles<br><span class="small">(send to students)</span></span>
			</ui-link>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { shuffle_property, sample } from '@/js/utils/utils'
import _ from '@/js/vendor/lodash.custom.min.js'
import Assignement from '@/components/master/room/roles/assignement'
import Card from '@/components/master/room/roles/card'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
	name: 'room-roles',
	data() {
		return {
			active_tab: '',
			roles_slider_settings: {
				itemsToShow: 1.5,
				touchDrag: false,
				mouseDrag: false,
				shortDrag: false,
				breakpoints: {
					1250: {
						itemsToShow: 2.5
					},
					1400: {
						itemsToShow: 3.5
					},
					1550: {
						itemsToShow: 4.5
					},
					1800: {
						itemsToShow: 5.5
					},
					1950: {
						itemsToShow: 6.5
					},
					2100: {
						itemsToShow: 7.5
					}
				}
			}
		}
	},
	computed: {
		...mapGetters([ 'master', 'game' ]),
		tabs() {
			return [ ...new Set (this.roles.map((r) => r.category)) ].sort()
		},
		players() {
			return this.game.players.filter((p) => { return p && !p.role || (p.role && !p.role.npc) })
		},
		roles() {
			return this.game.roles.filter((r) => { return r && ( !r.npc && !r.tag.includes('default') && r.scenario_id == this.game.room.scenario_id ) })
				.sort((a, b) => {
					if (a.category < b.category) return -1
					if (a.category > b.category) return 1
					return 0
				})
		}
	},
	methods: {
		slide(payload) {
			let slides = this.$el.querySelectorAll('.slider-roles .hooper-slide')
			let slide = slides[payload.currentSlide]
			if (slide) this.active_tab = slide.getAttribute('data-category')
		},
		goto_slide(category) {
			let slide = this.$el.querySelector('.slider-roles .hooper-slide[data-category="' + category + '"]')
			if (slide) {
				let slides = this.$el.querySelectorAll('.slider-roles .hooper-slide')
				let index = Array.from(slides).indexOf(slide)
				this.$refs.carousel.slideTo(index)
			}
		},
		prev() {
			this.$refs.carousel.slidePrev()
		},
		next() {
			this.$refs.carousel.slideNext()
		},
		assign_roles() {
			let players = _.cloneDeep(this.players)
			let importances = [ ...new Set(this.roles
				.filter((r) => r.importance && !players.find((p) => p.role && p.role.id === r.id))
				.map((r) => r.importance)
				.sort((a, b) => {
					if (a < b) return -1
					if (a > b) return 1
					return 0
				})) ]
			let importance_index = 0

			players.forEach((player) => {
				console.log('DEFAULT ROLE ID : rooms-roles.vue')
				if ((!player.role || parseInt(player.role.id) === 3) && importances[importance_index]) {
					let role = sample(this.roles.filter((r) => r.importance === importances[importance_index] && !players.find((p) => p.role && p.role.id === r.id)))
					if (!role) {
						importance_index++
						if (importances[importance_index]) role = sample(this.roles.filter((r) => r.importance === importances[importance_index] && !players.find((p) => p.role && p.role.id === r.id)))
					}
					if (role) player.role = role
				}
			})

			this.$store.commit('EDIT_GAME_STATE', { players })
		},
		shuffle_roles() {
			let players = _.cloneDeep(this.players.filter((p) => !p.role_locked))
			shuffle_property(players, 'role')
			players = [ ...this.players.filter((p) => p.role_locked), ...players ]
			this.$store.commit('EDIT_GAME_STATE', { players })
		}
	},
	components: { Assignement, Card, Hooper, Slide, HooperNavigation }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.room-roles
	display block
	height 100%
	width 100%
	padding 16px 0
	user-select none
	.room-roles-content
		width calc(100% - 324px)
		height calc(100% - 80px)
		.title
			margin-bottom 20px
			padding-bottom 20px
			height 60px
			line-height 60px
			border-bottom 2px solid blue_lighter
			color blue_lighter
			text-transform uppercase
			font-size 2.4rem
			font-family 'Josefin Sans'
		.assignements
			height 'calc(100% - %s)' % (180px + 48px + 16px)
			margin-bottom 16px
			.no-players
				margin 32px 0 0 0
				// position absolute
				// left 50%
				// top 50%
				// transform translate(-50%, -50%)
				text-align center
				font-family 'Josefin Sans'
				.label
					text-transform uppercase
					font-size 2rem
					color blue_lighter
				.button
					display inline-block
					margin 8px 0 0 0
					padding 16px 24px 12px 24px
					border-radius 4px
					background-color blue
					font-size 1.6rem
					font-weight 700
					text-transform uppercase
					cursor pointer
					&:hover
						background-color lighten(blue, 5%)
		.roles
			height (180px + 48px)
			.tabs
				flex flex-start center
				height 32px
				margin 0 0 16px 0
				line-height (@height + 4px)
				font-family 'Josefin Sans'
				box-shadow inset 0 -2px 0 0 blue_lighter
				.tab
					width 25%
					height @height
					max-width 200px
					padding 0 16px
					text-transform uppercase
					white-space nowrap
					color blue_light
					font-weight 700
					cursor pointer
					text-align center
					&:hover
						color blue_lighter
					&.active
						background-color blue_lighter
						color blue_darker
			.slider-roles
				height 180px
				.hooper-slide
					padding-right 16px
			.slide-arrow
				position absolute
				bottom 0
				width 16px
				height 180px
				cursor pointer
				&:before
					content ''
					position absolute
					top calc(50% - 12px)
					width 0
					height 0
					border-style solid
				&.left
					left -16px
					border-right 2px solid blue_lighter
					&:before
						border-width 12px 16px 12px 0
						border-color transparent blue_lighter transparent transparent
				&.right
					right -16px
					border-left 2px solid blue_lighter
					&:before
						border-width 12px 0 12px 16px
						border-color transparent transparent transparent blue_lighter
		.dragging-role
			position fixed
			left 0
			top 0
			z-index 10
			pointer-events none
	>>> .hooper
		outline none
	.room-roles-side
		position absolute
		right 0
		top 0
		bottom 0
		width 300px
		height 100%
		padding-top 80px
		// background-color red
		.action
			height 64px
			width 300px
			flex flex-start center
			padding 0 0 0 24px
			margin 0 0 8px 0
			border-radius 4px
			background-color blue
			cursor pointer
			font-size 2rem
			font-family 'Josefin Sans'
			text-transform uppercase
			&:hover
				background-color lighten(blue, 5%)
			&.green
				background-color #3FB143
				&:hover
					background-color lighten(#3FB143, 10%)
			> span
				transform translateY(2px)
				.small
					font-size 1.2rem
					line-height 16px
					opacity 0.75
			.ui-icon
				margin 0 12px 0 0
	
</style>
