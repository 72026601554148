<template>
	<div class="view view-rooms" :class="{ wide: game.room }">

		<room v-if="game.room" />
		<rooms v-else />

		<room-add-player v-if="$route.name == 'add-player'" />
		
		<div class="footer">
			<ui-link to="/master" class="close"></ui-link>
			<ui-link to="/master/rooms/add" class="new-game">
				<ui-icon name="master/new-game" :size="24" />
				<span class="text">New Game</span>
			</ui-link>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Rooms from '@/components/master/rooms'
import Room from '@/components/master/room'
import RoomAddPlayer from '@/components/master/room/room-add-player'

export default {
	name: 'view-rooms',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'master', 'game' ]),
	},
	components: { Rooms, Room, RoomAddPlayer }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.view-rooms
		// .scroll
		// 	height calc(100% - 68px)
		.footer
			position absolute
			left 0
			right 0
			bottom 0
			height 64px
			background-color blue
			box-shadow 0 -4px blue_light
			flex center center
			.close
				width 40px
				height 40px
				background blue_lighter url(../../assets/img/close-blue-dark.svg) center center no-repeat
				background-size 20px 20px
				border-radius 50%
				cursor pointer
			.new-game
				flex center center
				height 48px
				width calc(100% - 96px)
				max-width 264px
				margin-left 24px
				// padding 0 24px
				background-color #3FB143
				border-radius 4px
				color #fff
				text-transform uppercase
				font-family 'Josefin Sans'
				font-size 2rem
				cursor pointer
				.ui-icon
					margin 0 8px 0 0
				> .text
					transform translateY(2px)
				

.main-view .content .view.view-rooms
	height 100%
	&.wide
		width 100%
		max-width 100%
	.footer
		display none
	
</style>
