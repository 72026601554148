<template>
	<div class="room">

		<room-menu />
		
		<div class="room-content">
			<room-roles v-if="$route.name == 'room-roles'" />
			<room-game-access v-if="is_game_access" />
			<room-stats v-if="is_stats" />
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import RoomMenu from '@/components/master/room/room-menu'
import RoomRoles from '@/components/master/room/room-roles'
import RoomGameAccess from '@/components/master/room/room-game-access'
import RoomStats from '@/components/master/room/room-stats'

export default {
	name: 'room',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'master', 'game' ]),
		is_game_access() {
			return this.$route.matched.findIndex((r) => r.name === 'room-game-access') > -1
		},
		is_stats() {
			return this.$route.matched.findIndex((r) => r.name === 'room-stats') > -1
		},
		current_algo_question() {
			if (this.master.questions.length > 0) {
				const t = this.game.room.timer_total - this.game.room.timer
				return this.master.questions.find(q => q.timer < t && q.timer + q.timeout > t && q.type === 'Algorithmic' && q.day === this.game.room.episode.episode_number)
			}
			return null
		},
	},
	watch: {
		current_algo_question: {
			async handler(newQuestion, oldQuestion) {
				if (!newQuestion && oldQuestion && this.game.room.state === 'running') {
					console.log('End question')
					const result = await Apollo.query('GET_POLLING_RESULT', { room_id: this.game.room.id, question_id: oldQuestion.id, calculate: false })
					if (result) {
						this.$store.commit('EDIT_APP_STATE', {
							algorithmic_results: {
								question: oldQuestion,
								majority_answer: result
							}
						})
					}
				}
			}
		}
	},
	components: { RoomMenu, RoomRoles, RoomGameAccess, RoomStats }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile, #app.tablet-vertical
	.room
		padding 24px
		
	
.room
	height calc(100% - 80px)
	// padding 24px 8px
	flex flex-start flex-start
	.room-menu
		height 100%
		width 350px
		padding 32px
		margin 0 16px 0 0 
	.room-content
		height 100%
		width calc(100% - (350px + 16px))
		padding 0 16px
	
	
</style>
