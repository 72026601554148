<template>
	<div v-if="message" class="ui-likes-editor">
		<div class="button minus" v-hammer:tap="decrement">
			-
			<!-- <div class="tooltip"><p>Click : -1</p><p>Shift + Click : -5</p></div> -->
		</div>
		<div class="counter">
			<input type="number" class="nb" v-model="n" min="0" max="99">
			<!-- <span class="nb">{{ n | twice }}</span> -->
			<ui-icon name="chirp/like-on" :size="24" />
		</div>
		<div class="button plus" v-hammer:tap="increment">
			+
			<!-- <div class="tooltip"><p>Click : +1</p><p>Shift + Click : +5</p></div> -->
		</div>

	</div>
</template>

<script>
import { debounce } from '@/js/utils/utils'

let starting = true

export default {
	name: 'ui-likes-editor',
	data() {
		return {
			n: 0
		}
	},
	props: {
		message: { default: null }
	},
	filters: {
		twice(value) {
			return (value < 10 ? '0' : '') + value
		}
	},
	watch: {
		n(value, oldValue) {
			if (this.n > 99) this.n = 99
			if (this.n < 0) this.n = 0

			if (!starting) this.set_likes()
		}
	},
	methods: {
		set_likes: debounce(function() {
			this.$store.dispatch('set_like_message', { message: this.message, like: this.n })
		}, 500),
		increment(e) {
			this.n = parseInt(this.n)
			if (!Number.isInteger(this.n)) this.n = 0
			this.n += (e.srcEvent.shiftKey) ? 5 : 1
		},
		decrement(e) {
			this.n = parseInt(this.n)
			if (!Number.isInteger(this.n)) this.n = 0
			this.n -= (e.srcEvent.shiftKey) ? 5 : 1
		},
	},
	created() {
		starting = true
		this.n = this.message.like
		this.$nextTick(() => {
			starting = false
		})
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.ui-likes-editor
	flex center center
	height 40px
	width (40px * 2 + 88px)
	.button
		width @height
		height @height
		flex center center
		font-size 3.2rem
		cursor pointer
		&:hover
			.tooltip
				display block
	.counter
		height 100%
		flex center center
		width 88px
		background-color #002331
		border-radius 4px
		font-size 2.4rem
		.nb
			width 32px
			margin 0 8px 0 0
			border none
			background none
			font inherit
			color inherit
			text-align center
			appearance none
	.tooltip
		display none
		position absolute
		left 50%
		bottom calc(100% + 8px)
		transform translateX(-50%)
		padding 8px 16px
		font-size 1.2rem
		color gray_light
		background-color blue_darker
		box-shadow 0 0 0 2px blue_lighter, -2px 2px 6px #000
		border-radius 4px
		white-space nowrap
		&:hover
			display none
		

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button
	appearance none
	-moz-appearance none
	-webkit-appearance none
	margin 0

</style>
