<template>
	<div class="news">
		<template v-if="stories.length">
			<news-story v-for="story in stories" :story="story" :key="story.id" />
		</template>

		<div class="no-news" v-else>No news found</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { slugify } from '@/js/utils/utils'
import NewsStory from '@/components/game/news/news-story'

export default {
	name: 'news',
	data() {
		return {
			
		}
	},
	props: {
		player_filter: { default: null },
		view_filter: { default: null },
	},
	computed: {
		...mapGetters([ 'chirp' ]),
		stories() {
			let stories = [ ...this.chirp.stories ]
			if (this.player_filter && this.player_filter.id) {
				stories = stories.filter((c) => {
					if (c.sender_player && c.sender_player.id) return parseInt(c.sender_player.id) === parseInt(this.player_filter.id)
					return false
				})
			}
			if (this.view_filter === 'student') {
				stories = stories.filter((c) => c.sender_player && c.sender_player.student_number && c.sender_player.student_number !== "N/A")
			}
			return stories.concat().sort((a, b) => { return (a.created > b.created) ? -1 : 1 })
		}
	},
	created() {
		// this.$store.dispatch('load_chirps')
		// this.$store.dispatch('update_chirps')
	},
	components: { NewsStory }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.news
	height 100%
	.no-news
		width 100%
		margin 80px 0 0 0
		text-align center
		font-family 'Josefin Sans'
		font-size 2rem
		text-transform uppercase
		color blue_lighter
		opacity 0.25


</style>
