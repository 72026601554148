<template>
	<div class="student-assessments">
		<div class="empty">
			<div class="title">Unavailable before the end of the game.</div>
			<div class="text">
				<p>Once the game has reached it's conclusion, students will be asked to fill out an assessments of their own experience, what they liked and disliked, how they felt they did, etc.</p>
				<p>These will be made available to you, student by student.</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'student-assessments',
	props: {
		player_filter: { default: null },
		view_filter: { default: null },
	},
	computed: {
		...mapGetters([ 'master' ])
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.student-assessments
	height 100%
	padding-top 8px
	.empty
		width 90%
		margin 80px auto 0 auto
		text-align center
		font-size 2rem
		color blue_lighter
		line-height 1.4em
		.title
			padding 0 0 24px 0
			margin 0 0 24px 0
			border-bottom 2px solid blue_lighter
			font-size 2.4rem
			line-height 1.4em
		.text
			opacity 0.75
			p
				margin 0 0 16px 0

</style>
