<template>
	<div class="vote" :class="{ tiebreaker }">
		<ui-icon :name="icon" :size="16" />
		<span v-if="player" class="name">{{ player.name ? player.name : "N/A" }}</span>
		<span v-if="player && player.student_name" class="player">({{ player.student_name ? player.student_name : "N/A" }})</span>
		<span v-if="!player">N/A</span>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'vote',
	props: {
		player: { default: null },
		tiebreaker: { default: false }
	},
	computed: {
		...mapGetters([ 'game' ]),
		icon() {
			if (this.tiebreaker) return 'progress/user-crown'
			return 'progress/user-full'
		}
		// player() {
		// 	if (this.player_id) {
		// 		return this.game.players.find((p) => p.role && parseInt(p.role.id) === parseInt(this.player_id))
		// 	}
		// 	return null
		// }
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/variables'


.vote
	flex flex-start center
	padding 4px 0
	&.tiebreaker
		color #F2C555
		>>> .ui-icon
			transform scale(1.25) 
	>>> .ui-icon
		margin 0 8px 0 0
	.name
		margin 0 8px 0 0

</style>
