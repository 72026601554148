<template>
	<div class="decisions">

		<template v-if="questions.length">
			<div v-for="day in days" :key="day" class="group">
				<div class="group-title">Episode {{ day + 1 }} <span v-if="day === game.room.episode.episode_number">(current)</span></div>
				<div v-if="questions.filter((q) => q.day === day).length > 0" class="group-content">
					<single-decision v-for="question in questions.filter((q) => q.day === day)" :key="question.id" :question="question" />
				</div>
				<div v-else class="group-content">
					<div class="no-decisions">No decision yet for this episode</div>
				</div>
			</div>
		</template>

		<div class="no-decisions" v-else>No decisions found</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SingleDecision from '@/components/master/room/game-access/decisions/single-decision'

export default {
	name: 'decisions',
	data() {
		return {
			
		}
	},
	props: {
		questions: { default: [] },
		days: { default: [] },
		player_filter: { default: null },
		view_filter: { default: null }
	},
	computed: {
		...mapGetters([ 'master', 'game' ])
	},
	components: { SingleDecision }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.decisions
	height 100%
	
	.group
		margin 0 0 64px 0
		.group-title
			height 40px
			margin 0 0 16px 0
			border-radius 4px
			text-align center
			font-family 'Josefin Sans'
			font-size 2rem
			line-height (@height + 5px)
			text-transform uppercase
			color blue_dark
			background-color blue_lighter
		.group-content
			display block
		
	.no-decisions
		width 100%
		margin 64px 0 0 0
		text-align center
		font-family 'Josefin Sans'
		font-size 2rem
		text-transform uppercase
		color blue_lighter
		opacity 0.25


</style>
